<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH EMPLOYEE PAYABLES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-layout column wrap class="mx-2 ">
          <v-layout row wrap>
            <v-flex xs12 md3>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-select
                    v-model="selection"
                    class="mx-2"
                    dense
                    label="Selection"
                    :items="['Active','Temporary Deactivated','Permanent Deactivated']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="category_id"
                    class="mx-2"
                    dense
                    label="Category"
                    :items="category_items"
                    item-text="category"
                    item-value="id"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="search_by"
                    class="mx-2"
                    dense
                    label="Search By"
                    :items="['Name','Branch','Account #']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12 v-if="search_by!=''">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="search"
                    :loading="isLoading"
                    @keyup.enter="searching($event.target.value)"
                    label="Search"
                    hide-no-data
                    placeholder="Press Enter to Search "
                    :prepend-icon="icons.mdiAccountSearchOutline"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="search_items.length>0">
                  <v-data-table dense
                                :headers="headers"
                                height="600"
                                :items="search_items"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        @click="get_search_items_info(item)"
                        :class="{'info white--text': item.id===selectedDepositId}"
                      >
                        <td>
                          {{ item.name
                          }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md9>
              <h2>Payables Data | Total: {{ total_amount }} | Balance: {{ total_balance }} Print |
                <v-icon class="mr-2" color="success" @click="print_data"
                        v-if="!printt && payables_items.length>0">
                  {{ icons.mdiPrinter }}
                </v-icon>
                <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                     v-if="printt"></v-progress-circular>
              </h2>
              <v-data-table dense
                            :headers="headers2"
                            :items="payables_items"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.type }}
                    </td>
                    <td>
                      {{ item.details}}
                    </td>
                    <td>
                      {{ formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ formatPrice(item.balance)}}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline,
    mdiCharity, mdiHandshakeOutline, mdiCubeScan, mdiGift,mdiPrinter
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import EmployeeDetails
    from '@/views/file_interface/search/components_emloyee/tabs/EmployeeDetails'
  import Account from '@/views/file_interface/search/components_emloyee/PersonalInformation'
  import Foundation from '@/views/file_interface/search/components_emloyee/EmploymentStatus'
  import DistrictClub from '@/views/file_interface/search/components_emloyee/CompensationHistory'
  import Donation from '@/views/file_interface/search/components_emloyee/MemoHistory'
  import Participation from '@/views/file_interface/search/components_emloyee/ChangeHistory'
  import moment from "moment";

  export default {
    components: {
      EmployeeDetails,
      Account,
      Foundation,
      DistrictClub,
      Donation,
      Participation,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        keeyyyy: 0,
        search: '',
        search_items: [],
        isLoading: false,
        is_searching: false,
        printt: false,

        selectedDepositId: -1,
        selected_data: {},
        selection: '',
        search_by: '',
        category_id: '',
        total_amount: '0.00',
        total_balance: '0.00',
        category_items: [],

        cropped: this.avatarImg,
        branch: '',
        member_information: {category: '', branch: '', employee_position: ''},
        compensation_histories: [],
        memo_history: [],
        change_history: [],
        headers: [
          {text: 'Details', value: 'name', sortable: false},
        ],
        headers2: [
          {text: 'Type', value: 'name', sortable: false},
          {text: 'Details', value: 'name', sortable: false},
          {text: 'Amount', value: 'name', sortable: false},
          {text: 'Balance', value: 'name', sortable: false},
        ],
        payables_items: [],
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('employee', ['search_all_by_status_employee',]),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('auto_loan', ['search_auto_loan_personal',]),
      ...mapActions('request_data', ['search_of_cash_advance',]),
      ...mapActions('request', ['search_coop_loan_to_coop_api',]),
      ...mapActions('loan_application', ['search_list_of_loan']),
      ...mapActions('motor_finance', ['search_list_of_motor',]),
      ...mapActions('other_receivables', ['search_list_of_other_receivable']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async searching(value) {
        this.branch = ''

        this.isLoading = true
        this.is_searching = true
        await this.search_all_by_status_employee({
          search_word: value,
          category_id: this.category_id,
          status: this.selection,
          search_by: this.search_by,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      selected() {
        this.selectedDepositId = -1
        this.search = ''
        this.search_items = []
        this.isLoading = false
        this.payables_items = []
      },
      async get_search_items_info(value) {
        if (this.is_searching) {
          this.selected_data=value
          this.is_searching = false
          this.payables_items = []
          this.total_amount = '0.00'
          this.total_balance = '0.00'
          this.keeyyyy++
          this.selectedDepositId = value.id
          var ddd = []
          var aamm = 0
          var balll = 0
          await this.search_auto_loan_personal({
            search_word: value.credit_to,
            status: 'Unpaid'
          })
            .then(response => {
              response.data.forEach(function (item) {
                ddd.push({
                  id: item.id + 'AUTO',
                  type: 'AUTO LOAN',
                  details: item.brand + ' ' + item.unit,
                  amount: item.borrower_loan,
                  balance: item.balance
                },)
                aamm += parseFloat(item.borrower_loan)
                balll += parseFloat(item.balance)
              })
            })
          await this.search_of_cash_advance({
            search_word: value.credit_to,
            status: 'Unpaid'
          })
            .then(response => {
              response.data[0].personal.forEach(function (item) {
                var total = item.amount
                var payment = 0
                item.ca_payment.forEach(function (item) {
                  payment += parseFloat(item.amount)
                });
                var balance = (total - payment)
                ddd.push({
                  id: item.id + 'CA',
                  type: 'CASH ADVANCE',
                  details: item.description,
                  amount: item.amount,
                  balance: balance
                },)
                aamm += parseFloat(item.amount)
                balll += parseFloat(balance)
              })
            })
          await this.search_list_of_loan({
            search_word: value.id,
            status: 'Unpaid'
          })
            .then(response => {
              response.data[0].employee.forEach(function (item) {
                var total = item.total
                var payment = 0
                item.payment.forEach(function (item) {
                  payment += parseFloat(item.amount)
                });
                var balance = (total - payment)
                ddd.push({
                  id: item.id + 'LOAN',
                  type: 'LOAN',
                  details: item.purpose,
                  amount: item.amount,
                  balance: balance
                },)
                aamm += parseFloat(item.amount)
                balll += parseFloat(balance)
              })
            })
          await this.search_list_of_motor({
            search_word: value.id,
            status: 'Unpaid'
          })
            .then(response => {
              response.data[0].employee.forEach(function (item) {
                var total = item.amount
                var payment = 0
                item.payment.forEach(function (item) {
                  payment += parseFloat(item.amount)
                });
                var balance = (total - payment)
                ddd.push({
                  id: item.id + 'MOTOR',
                  type: 'MOTOR FINANCE',
                  details: item.purpose,
                  amount: item.amount,
                  balance: balance
                },)
                aamm += parseFloat(item.amount)
                balll += parseFloat(balance)
              })
            })
          await this.search_list_of_other_receivable({
            search_word: value.id,
            status: 'Unpaid'
          })
            .then(response => {
              response.data[0].employee.forEach(function (item) {
                var total = item.amount
                var payment = 0
                item.payment.forEach(function (item) {
                  payment += parseFloat(item.amount)
                });
                var balance = (total - payment)
                ddd.push({
                  id: item.id + 'OTHER',
                  type: 'OTHER RECEIVABLE',
                  details: item.purpose,
                  amount: item.amount,
                  balance: balance
                },)
                aamm += parseFloat(item.amount)
                balll += parseFloat(balance)
              })
            })
          await this.search_coop_loan_to_coop_api({
            last_name: value.details.last_name,
            first_name: value.details.first_name,
          })
            .then(response => {
              response.data.response.forEach(function (item) {
                ddd.push({
                  id: item.id + 'COOP LOAN',
                  type: 'COOP LOAN',
                  details: item.type,
                  amount: item.amount_due,
                  balance: item.balance
                },)
                aamm += parseFloat(item.total)
                balll += parseFloat(item.balance)
              })
            })
          this.total_amount = this.formatPrice(aamm)
          this.total_balance = this.formatPrice(balll)
          this.payables_items = ddd
          this.isLoading = false
          this.is_searching = true
        }
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []

        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [100, 230, 60, 60,]
        payments_array.push([
          {text: 'TYPE', alignment: 'left', style: 'main_info'},
          {text: 'DETAILS', alignment: 'left', style: 'main_info'},
          {text: 'AMOUNT', alignment: 'left', style: 'main_info'},
          {text: 'BALANCE', alignment: 'left', style: 'main_info'},
        ])
        var tott = this.total_amount
        var tott_bal = this.total_balance
        this.payables_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.type,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.details,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.amount === 0
                  ? ''
                  : (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.balance === 0
                  ? ''
                  : (item.balance / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: tott_bal,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Name: ',
                  {
                    text: this.selected_data.details.last_name+', '+this.selected_data.details.first_name,
                    style: 'main_info',
                  },
                ],
              },

            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'EMPLOYEE PAYABLES',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {

                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    }
  }
</script>
